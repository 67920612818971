import React from "react"

import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"

const NotFoundPage = () => (
  <Layout fluid={true}>
    <SEO
      title="Stránka nebola nájdená"
      description="Autoškola HRON - požadovaná stránka nebola nájdená"
    />

    <Container fluid={false}>
      <div  data-sal-duration="650"
            data-sal="fade"
            data-sal-easing="ease-in">
      <h1>Stránka nebola nájdená</h1>
      <p>Prosím, skontrolujte, či ste zadali správnu adresu do prehliadača.</p>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
